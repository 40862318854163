.Table {
  border-spacing: 0px;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  width: 100%;
}

.DataTable {
  position: relative;
  max-width: 100vw;
}

.Cell {
  border: 1px solid #e8ecf0;
  padding: 4px;
  text-align: left;
  vertical-align: top;
}

.Cell-header {
  background: #f4f6f8;
}

.Cell-fixed {
  width: 75px;
}

.ScrollContainer {
  overflow-x: auto;
  margin-left: 145px;
}